html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
}

.proca-widget-wrapper {
  --content-padding: 16px;
  --font-size-l: 28px;
  --font-size-m: 18px;
  --color-primary: #a3151a;
  --color-secondary: #14173f;
  --color-highlight: #95c4d9;
  --gutter-m: 32px;
  padding: var(--content-padding);
  padding-bottom: var(--content-padding);
  font-family: Libre Franklin, sans-serif;
  font-size: var(--font-size-m);
  justify-content: center;
  display: flex;
}

.proca-progress {
  text-align: center;
}

.proca-widget-wrapper .max-content {
  max-width: 1200px;
}

@media screen and (width <= 768px) {
  .proca-widget-wrapper {
    --font-size-l: 1.5rem;
    --padding-top: 0;
  }
}

/*# sourceMappingURL=index.57848b09.css.map */
