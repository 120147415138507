html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
}

.proca-widget-wrapper {
    --content-padding: 16px;
    --font-size-l: 28px;
    --font-size-m: 18px;
    --color-primary: #a3151a;
    --color-secondary: #14173F;
    --color-highlight: #95C4D9;
    --gutter-m: 32px;
    padding: var(--content-padding);
    padding-bottom: var(--content-padding);
    font-family: 'Libre Franklin', sans-serif;
    font-size: var(--font-size-m);
    display: flex;
    justify-content: center;
}

.proca-progress {
    text-align: center;
}

.proca-widget-wrapper .max-content {
    max-width: 1200px;
}

@media screen and (max-width: 768px) {
    .proca-widget-wrapper {
        --font-size-l: 1.5rem;
        --padding-top: 0;
    }
}
